<template>
  <div>
    <v-container fluid fill-height>
      <c-ecosystem-nav :ecosystemId="ecosystemId"></c-ecosystem-nav>

      <v-row v-if="user && user.givenName">
        <v-col cols="12" md="10" offset-md="1">
          <v-container :fluid="$vuetify.breakpoint.lgAndDown">
            <v-row class="pt-10">
              <div class="d-flex justify-space-between align-center" style="width: 100%">
                <router-link :to="`/ws/${ecosystemId}/users`" class="back-link">
                  <v-icon color="primary">mdi-chevron-left</v-icon>
                  Back
                </router-link>
                <v-btn
                  data-cy="pin-button"
                  type="button"
                  @click.prevent="generatePin"
                  :disabled="$store.getters['ui/loading'].isLoading"
                  color="primary"
                  class="generate-pin-btn"
                  outlined
                >
                  <img class="generate-pin-icon" src="/images/key.png" alt="key" />
                  Generate Gatherings Key
                </v-btn>
              </div>
              <div class="content-container">
                <div class="user-profile">
                  <div class="profile-section">
                    <div class="d-flex justify-center relative">
                      <!-- <div class="presenter-pill">Presenter</div> -->

                      <v-avatar size="136" color="primary">
                        <img
                          v-if="avatarUrl"
                          style="height: auto"
                          :src="avatarUrl"
                          :alt="`${user.givenName} ${user.surname}`"
                        />
                        <span v-else class="white--text text-h3">
                          {{ userInitials }}
                        </span>
                      </v-avatar>

                      <div class="edit-button absolute" @click="editUser">
                        <img class="edit-icon" src="/images/edit.png" alt="Edit" />
                      </div>
                    </div>

                    <div class="d-flex flex-column justify-center align-center mt-4">
                      <div class="main-details">
                        <div class="user-name">{{ user.givenName }} {{ user.surname }}</div>

                        <div class="company-name">
                          <span v-if="user.company">
                            {{ user.company }}
                          </span>
                          <span v-else> - </span>
                        </div>

                        <div class="user-email">{{ user.email }}</div>
                      </div>

                      <div plain class="role-box">{{ role }}</div>
                    </div>
                  </div>

                  <div class="divider"></div>

                  <div class="profile-section">
                    <div class="general-details-title">General details</div>
                    <div class="kv-pair">
                      <div>Default space:</div>
                      <div>{{ defaultSpaceName }}</div>
                    </div>
                    <!-- <div class="kv-pair">
                      <div>VR experience:</div>
                      <div>Frequent and confident user of VR</div>
                    </div> -->
                  </div>
                </div>

                <div class="tabs-container">
                  <!-- Tab headings -->
                  <v-tabs v-model="tab" class="tab-headers" active-class="active-tab">
                    <v-tab class="tab-header">Onboarding </v-tab>
                    <v-tab class="tab-header"> Activity </v-tab>
                    <v-tab class="tab-header"> Avatars </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab" class="pa-6">
                    <!-- First tab content-->
                    <v-tab-item :transition="false">
                      <c-user-onboarding-content :user="user" />
                    </v-tab-item>
                    <!-- Second tab content-->
                    <v-tab-item :transition="false">
                      <c-user-activity-content :user="user" />
                    </v-tab-item>
                    <!-- Third tab content -->
                    <v-tab-item :transition="false">
                      <c-user-avatar-history :user="user" />
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-else class="d-flex justify-center align-center" style="height: 100vh">
        <c-loading-spinner />
      </v-row>
    </v-container>

    <!-- User upsert dialog -->
    <c-upsert-modal
      title="User details"
      :show="showUpsert"
      :submitBtnText="userUpsertSubmitBtnText"
      :disableSubmit="!validUserUpsert"
      :showDeleteBtn="$store.getters['auth/user'].id !== dialogUser.id"
      deleteBtnText="Remove user"
      v-on:submit="submitUpsert"
      v-on:delete="showConfirmDeleteDialog = true"
      v-on:close="closeUserUpsert"
    >
      <c-user-upsert-content
        title="Provide the user details."
        :user="dialogUser"
        :ecosystemId="ecosystemId"
        v-on:validate="validUserUpsert = $event"
      />
    </c-upsert-modal>

    <!-- Pin number dialog -->
    <c-pin-dialog
      :show="showPinDialog"
      :pin="pin"
      v-on:close="
        showPinDialog = false
        pin = ''
      "
    />

    <!-- Confirm bulk delete users -->
    <c-confirm-delete-dialog
      :show="showConfirmDeleteDialog"
      :quantity="1"
      itemName="user"
      actionName="Remove"
      v-on:decision="removeUser"
      v-on:close="showConfirmDeleteDialog = false"
    >
      <template v-slot:question> Remove this user from the ecosystem? </template>
      <template v-slot:detail>
        <div>
          {{ user.givenName }} {{ user.surname }} will only be removed from this ecoystem. <br />
          Their account will not be deleted from the database
        </div>
      </template>
    </c-confirm-delete-dialog>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
const _ = require('lodash')
export default {
  props: ['ecosystemId', 'userId'],
  data: () => ({
    tab: null,
    user: {},
    pin: undefined,
    loading: false,
    defaultSpaces: [],
    showUpsert: false,
    showPinDialog: false,
    validUserUpsert: false,
    dialogUser: {},
    showConfirmDeleteDialog: false,
  }),
  computed: {
    role() {
      // user 0/admin 2/guest 4
      return this.user.userType == 4 ? 'Guest' : this.user.userType == 2 ? 'Administrator' : 'User'
    },
    avatarUrl() {
      if (!this.user) {
        return undefined
      }

      return this.user.activeAvatarThumbnail ?? this.user.avatars?.active?.thumbnail ?? undefined
    },
    defaultSpaceName() {
      return this.defaultSpaces.find(g => g.value == this.user.defaultGatheringId)?.text ?? '-'
    },
    userInitials() {
      if (
        this.user?.givenName &&
        this.user?.givenName.length &&
        this.user?.surname &&
        this.user?.surname.length
      ) {
        return `${this.user.givenName[0]}${this.user.surname[0]}`.toUpperCase()
      }
      return ''
    },
    userUpsertSubmitBtnText() {
      return this.dialogUser.givenName ? 'Update user' : 'Add user'
    },
  },
  created() {
    // Get stored ecosystem
    this.ecosystem = this.$store.getters['ecosystems/getById'](this.ecosystemId)

    // If stored ecosystem is shallow, get full ecosystem
    this.$store.dispatch('ecosystems/fetchById', this.ecosystemId).then(() => {
      this.ecosystem = this.$store.getters['ecosystems/getById'](this.ecosystemId)
    })

    // Get all ecosystems users and then fetch this user
    this.loading = true
    this.$store.dispatch('ecosystems/fetchUsers', this.ecosystemId).then(() => {
      this.$store
        .dispatch('ecosystems/fetchUserById', {
          ecosystemId: this.ecosystemId,
          userId: this.userId,
        })
        .then(() => {
          this.user = this.$store.getters['ecosystems/getUserById'](this.ecosystemId, this.userId)
          this.loading = false
        })
    })
  },

  mounted() {
    this.dialogUser = this.defaultUser()
    this.$store
      .dispatch('spaces/fetchDefaults', {
        ecosystemId: this.ecosystemId,
        user: this.$store.getters['auth/user'],
      })
      .then(() => {
        this.defaultSpaces = this.$store.getters['spaces/defaults'].map(g => {
          return {
            value: g.id,
            text: g.name,
          }
        })
      })
  },
  methods: {
    generatePin() {
      this.$store.dispatch('ui/toggleLoading', { isLoading: true })
      this.$store.dispatch('devices/getPairingCode', this.user).then(
        () => {
          this.pin = this.$store.getters['devices/pairingCode']
          this.showPinDialog = true
          this.$store.dispatch('ui/toggleLoading', { isLoading: false })
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { isLoading: false })
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
    editUser() {
      this.dialogUser = {
        ..._.pick(
          this.user,
          'id',
          'givenName',
          'surname',
          'email',
          'company',
          'jobTitle',
          'userTimeZone',
          'defaultGatheringId',
          'userType'
        ),
        isNew: false,
      }
      this.showUpsert = true
    },
    submitUpsert() {
      if (!this.validUserUpsert) {
        return
      }

      this.$store.dispatch('ui/toggleLoading', { isLoading: true })

      const { userType, ...dialogUser } = this.dialogUser
      const data = {
        ecosystemId: this.ecosystemId,
        user: { ...dialogUser, timeZone: dialogUser.userTimeZone },
        userType,
      }

      this.updateUser(data)
    },
    updateUser(data) {
      this.$store
        .dispatch('ecosystems/updateUser', {
          ecosystemId: this.ecosystemId,
          userId: this.dialogUser.id,
          data: data,
        })
        .then(
          () => {
            this.user = this.$store.getters['ecosystems/getUserById'](this.ecosystemId, this.userId)
            const params = {
              color: 'green',
              message: 'User updated',
              buttonColor: 'white',
            }
            this.$store.dispatch('ui/launchSnackbar', params)
            this.$store.dispatch('ui/toggleLoading', { isLoading: false })
            this.closeUserUpsert()
          },
          error => {
            this.$store.dispatch('ui/toggleLoading', { isLoading: false })
            this.closeUserUpsert()
            this.message =
              (error.response && error.response.data) || error.message || error.toString()
          }
        )
    },
    closeUserUpsert() {
      this.showUpsert = false
      this.dialogUser = this.defaultUser()
    },
    defaultUser() {
      return {
        id: uuidv4(),
        givenName: null,
        surname: null,
        email: null,
        company: null,
        jobTitle: null,
        userTimeZone: null,
        defaultGatheringId: null,
        userType: 2,
        isNew: true,
      }
    },
    async removeUser(decision) {
      if (decision === 'no') {
        this.showConfirmDeleteDialog = false
        return
      }

      try {
        this.$store.dispatch('ui/toggleLoading', { isLoading: true })
        await this.$store.dispatch('ecosystems/removeUsers', {
          ecosystemId: this.ecosystemId,
          users: [this.user],
        })
        this.$store.dispatch('ui/toggleLoading', { isLoading: false })
        this.showConfirmDeleteDialog = false
        this.closeUserUpsert()
        this.$store.dispatch('ui/launchSnackbar', {
          color: 'green',
          message: `User removed`,
          buttonColor: 'white',
        })
        this.$router.push({ path: `/ws/${this.ecosystemId}/users` })
      } catch (error) {
        this.$store.dispatch('ui/toggleLoading', { isLoading: false })
        this.showConfirmDeleteDialog = false
        this.closeUserUpsert()
        this.$store.dispatch('ui/launchSnackbar', {
          color: 'error',
          message: 'Sorry something went wrong and we could not remove the user',
          buttonColor: 'white',
        })
      }
    },
  },
}
</script>
<style scoped lang="scss">
.back {
  font-weight: bold;
  font-size: 17px;
}

.content-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
  width: 100%;
}

.user-profile {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  min-width: 350px;
  max-height: 500px;
  width: 100%;
  gap: 16px;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 8px;
}

@media screen and (min-width: 960px) {
  .user-profile {
    width: 33%;
  }
}

.tabs-container {
  box-sizing: border-box;
  padding: 2px;
  isolation: isolate;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 8px;
  flex-grow: 1;
  min-width: 350px;
}

.tab-headers {
  border-bottom: solid 1px #e8eef3;
  padding: 24px 24px 0px 24px;
}

.tab-header {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1f2329;
  text-transform: none !important;
  letter-spacing: normal !important;
}

.active-tab {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1f2329;
}

.back-link {
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.profile-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.divider {
  border-bottom: solid 1px #e8eef3;
  width: 100%;
}

.presenter-pill {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #807ef0;
  height: 22px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 8px 6px;
  background: #f8f8ff;
  border-radius: 6px;
}

.edit-button {
  padding: 3px 3px 4px 4px;
  cursor: pointer;
  right: 3px;
  top: 3px;
}

.edit-icon {
  width: 17px;
  height: auto;
}

.main-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.user-name {
  font-size: 38px;
  line-height: 46px;
  color: #1f2329;
}

.company-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #464d54;
}

.user-email {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #69737a;
}

.role-box {
  margin-top: 12px;
  padding: 6px 12px;
  border: 1px solid #dce2e9;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1f2329;
}

.general-details-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1f2329;
}

.kv-pair {
  display: flex;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.kv-pair div:nth-child(1) {
  color: #69737a;
  min-width: 110px;
}

.kv-pair div:nth-child(2) {
  color: #1f2329;
}

.generate-pin-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #807ef0;
  text-transform: none;
  border: none !important;
  padding: 25px 20px !important;
}

.generate-pin-icon {
  width: 24px;
  height: auto;
  margin-right: 8px;
}
</style>
